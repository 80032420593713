@import url(https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#contact {
    padding: 7rem 0 6rem;
    overflow: hidden;
}
.sectionTitle {
    margin: 0.5rem 0 3rem;
    font-size: 2rem;
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-bottom: 0.6rem;
}
.sectionTitle::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100px;
    border-radius: 70px;
    height: 4px;
    background: #7355F7;
}

.contactForm {
    padding: 1rem;
}
.contactForm input,
.contactForm textarea {
    width: 100%;
    margin: 0.5rem 0;
    padding: 1rem 0.8rem;
    border-radius: 0.3rem;
    border: 1px solid #bebebe;
    color: #000;
}
.contactForm input:focus,
.contactForm textarea:focus {
    outline: none !important;
    border: 1px solid #7054F2;
    background-color: rgb(243, 240, 255);
}
.contactForm textarea {
    height: 120px;
}
.scrollBtn {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    z-index: 112;
    display: inline-block;
    height: 2.4rem;
    width: 2.4rem;
    background: #7154F3;
    border-radius: 50%;
    transition: 0.3s;
    border: none;
    box-shadow: 0 2px 15px rgb(88 69 206 / 70%);
}
.scrollBtn:hover {
    background-color: #4B24F5;
}
.footer {
    background: #7355F7;
    background-image: url(../../static/media/footerbg.23fa6ea0.png);
    width: 100%;
    font-family: 'Poppins', sans-serif;
    margin: 0!important;
}
.footerBox .footerLogo {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0px 60px 0 rgb(0 0 0 / 20%);
    height: 70px;
    padding: 0.5rem;
}
.linkIcon {
    color: #6F53EF;
    font-size: 2rem;
    cursor: pointer;
    background: white;
    padding: 5px;
    margin: 0 0.5rem;
    border-radius: 0.3rem;
}
.footerLink,
.fAboutUs {
    padding: 1.5rem 2rem;
}

.footerLink h5,
.fAboutUs h5 {
    color: rgba(240, 255, 255, 0.925);
    padding-top: 0.5rem;
    font-weight: 400;
    display: inline-block;
    position: relative;
}
.footerLink h5 {
    padding-bottom: 0.5rem;
}
.footerLink h5::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 60px;
    border-radius: 70px;
    height: 4px;
    background: rgba(240, 255, 255, 0.925);;
}
.footerLink > .aboutUsDes {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
}
.footerLink li {
    list-style: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    transition: 0.4s;
    margin: 1.1rem 0;
    font-weight: 400;
}
.footerLink li:hover {
    margin-left: 0.7rem;
    color: #fff;
}

.footerLink a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
}
.footerLink a:hover {
    text-decoration: none;
}
.footArrowIcon {
    font-size: 0.9rem;
}

.fAboutUs p {
    color: rgba(255, 255, 255, 0.699);
    font-weight: 400;
    margin: 1rem 0;

}
.fAboutUs ul {
    margin: 0;
    padding: 0;
}
.fAboutUs li {
    list-style: none;
    float: left;
}
.fAboutUs li a {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    text-align: center;
    line-height: 2.5rem;
    background: #fff;
    margin-right: 5px;
    color:#7355F7;
    transition: 0.4s;
}
.fAboutUs li a:hover{
    background: #4B24F5;
    color: #fff;
}

.copyRight {
    background-color: #2608AB;
    color: rgba(255, 255, 255, 0.692);
    font-weight: 400;
    font-size: 0.911rem;
    text-align: center;
    margin-bottom: 0;
    padding: 1rem 0;
}
.copyRight > .fHighlight {
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
}


/* FooterInfo */

.footerInfo {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding: 3rem 0 2rem;
}

.fContactInfo p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    margin-bottom: 0.2rem;
    margin-left: 0.7rem;
}
.fContactIcon {
    font-size: 2.5rem;
    color: rgba(255, 255, 255, 0.822);
}

.fContactInfo1  p {
    color: #fff!important;
    font-weight: 500;
    font-size: 1.5rem!important;
}
.fContactInfo1 .fContactIcon {
    color: #fff;
    font-weight: 500;
    font-size: 3rem;
}


/* Animation border */

.animate-border {
    position: relative;
    display: block;
    width: 115px;
    height: 3px;
    background: #2608AB;
}
  
.animate-border:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    left: 0;
    bottom: 0;
    border-left: 20px solid #fff;
    animation: animborder 3s linear infinite;
  }
  
@keyframes animborder {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(81px);
    }
}
.ourValue {
    background: #7355F7;
    padding: 2rem;
}
.ourValueDetails {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0px 60px 0 rgb(0 0 0 / 10%);
    padding: 1.3rem 1rem 1rem;
    transition: all 1s;
    text-align: center;
}
.valueIcon {
    padding: 0.4rem 0.8rem;
    font-size: 3rem;
    color: #fff;
    border-radius: 0.25rem
} 
.valueIcon1 {
    background: #029e76;
    box-shadow: 0 2px 15px rgb(2 158 118 / 50%);
}
.valueIcon2 {
    box-shadow: 0 2px 15px rgb(255 168 8 / 50%);
    background-color: #ffa808;
}
.valueIcon3 {
    box-shadow: 0 2px 15px rgb(85 67 209 / 50%);
    background-color: #5543d1;
}
.valueIcon4 {
    box-shadow: 0 2px 15px rgb(255 88 110 / 50%);
    background-color: #ff586e;
}
.ourValueNumber {
    font-weight: 700;
    font-size: 2.4rem;
    color: #7355F7;
}
.ourValueTitle {
    color: #777777;
    margin: 1.5rem 0 1rem 0;
    font-weight: 500;
    font-size: 1.2rem;
}

@media (max-width: 981px) {
    .ourValueDetails{
        margin-top: 1rem;
    }
}
.navStyle {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    background: white;
    position: fixed!important;
    width: 100%;
    z-index: 99!important;
    transition: 0.5s!important;
    font-family: 'Poppins', sans-serif;
}
.navDefault {
    transition: 0.5s!important;
    padding: 25px 0 40px 0!important;
    margin-bottom: 2rem;
}

.mainNav .nav-link {
    color: #070120!important;
    margin: 0.5rem 1.2rem!important;
    font-weight: 500!important;
    transition: 0.3s;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
}
.mainNav .nav-link:after {
    content: '';
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}
.mainNav .nav-link:hover:after {
    width: 100%;
    background: #4C25F5;
}

.navBrn {
    font-weight: 700;
    font-size: 1.5rem;
}
.brnIcon,
.navHighlight {
    color: #7456F7;;
}
.brnIcon {
    font-weight: 800;
    font-size: 1.6rem;
    margin-top: -1%;
    margin-left: 0%;
    margin-right: 1%;
}

.loginBtn {
    outline: none;
    border: none;
    border-radius: 50px;
    font-weight: 500;
    color:#fff;
    background: #7355F7;
    padding: 0.5rem 1.5rem;
    margin: 0.5rem 1rem;
    transition: 0.4s;
}
.loginBtn:hover { 
    background : #4B24F5
}
.navActiveClass{
    color:"red";
    font-weight:900;
}

@media (max-width: 981px) {
    .navDefault {
        background: white;
        padding: 0.8rem 0.5rem!important;
        width: 100%;
        z-index: 99!important;
        transition: 0.5s!important;
        text-align: center;
    }
    .navStyle{
        text-align: center;
    }
    
}
/* Default width for PC screens */
#responsiveImage {
    width: 11%;
  }
  
  /* Media query for mobile screens */
  @media (max-width: 768px) {
    #responsiveImage {
      width: 18%;
    }
    .navBrn{
        width: 70%;
      }  
  }
  

  @media (max-width: 981px){
  .navDefault, .navStyle {
      text-align: center;
      margin-top: 0%;
      margin-bottom: 0%;
  }}

 
.header {
    background: white;
    height: 100%;
    background-image: url(../../static/media/bg.431db87d.png);
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    overflow: hidden;
    

    
}
.titleArea {
    padding: 1rem;
}
.miniTitle {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #777777;
    text-transform: uppercase;
}
.headerTitle{
    margin-bottom: 10px;
    font-size: 60px;
    font-weight: 800;
    font-family: sans-serif!important;
}

.headerContent {
    font-size: 18px;
    line-height: 1.5;
    color: #5e5e5e;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin: 0.5rem 0 1rem
}
.branBtn {
    background: #7355F7;
    padding: 18px 35px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
}
.branBtn:hover {
    color: #fff;
    background-color: #4B24F5;
}
.headerHighlight {
    color: #7355F7;
}

@media (max-width: 981px) {
    .header{
        overflow: hidden;
        height: 100%;
    }
    .headerTitle{
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: 700;
        font-family: sans-serif!important;
    }
    navbar navbar-expand-lg navbar-light navDefault navbar navbar-expand-lg navbar-light{
    margin-top: 0;
    }
}
.pricing{
    padding: 7rem 0 6rem;
    font-family: 'Poppins', sans-serif;
}
.pricingNav {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}

.pricingNav img {
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pricingNav .nav-link {
    position: relative;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    transition: 0.3s;
}

.priceLink1 > .nav-link {
    border: 7px solid #ffccd3;
}
.priceLink2 > .nav-link {
    border: 7px solid #a2ffd2;
}
.priceLink3 > .nav-link {
    border: 7px solid #fdd2ff;
}
.priceLink4 > .nav-link {
    border: 7px solid #CBEDFF;
}
.priceLink5 > .nav-link {
    border: 7px solid #dfdaff;
}
.priceLink6 > .nav-link {
    border: 7px solid #ffeb94;
}

.priceLink1 > a:hover, 
.priceLink1 > .nav-link.active {
    background: #FF4F66;
}
.priceLink2 > a:hover, 
.priceLink2 > .nav-link.active {
    background: #4ED797;

}
.priceLink3 > a:hover, 
.priceLink3 > .nav-link.active {
    background: #F78CFF;
}
.priceLink4 > a:hover, 
.priceLink4 > .nav-link.active {
    background: #76CDFF;
}
.priceLink5 > a:hover, 
.priceLink5 > .nav-link.active {
    background: #755BFF;
}
.priceLink6 > a:hover, 
.priceLink6 > .nav-link.active {
    background: #ffd30f;
}


/* Pricing Card Component Design */

.pricingCard {
    box-shadow: 1px 0 50px rgb(0 0 0 / 9%);
    border-radius: 0.26rem !important;
    transition: all 1s;
    background-color: #fff;
    padding: 1rem 2rem;
    margin-top: 2.5rem;
}

.pricingBox {
    text-align: center;
    border-bottom: 1px solid rgba(119, 119, 119, 0.322);
    margin-bottom: 0.8rem;
}

.pricingBox h4 {
    font-weight: 700;
    padding: 0.5 0;
    margin-bottom: 0;
}
.pricingBox h5 {
    font-weight: 700;
    font-size: 1rem;
}

.pricingBox .pricePlan {
    font-weight: 600;
    margin: 0.7rem 0;
}

.planDescription{
    font-size: 15px;
    color: #777;
    line-height: 1.8;
}
.pricingCard li {
    list-style: none;
    margin: 0.2rem 0;
}
.ph1, 
.ph2, 
.ph3, 
.ph4,
.ph5,
.ph6 {
    font-size: 1.6rem;
    font-weight: 700;
}
.pricingCard1 {
    background: #FFD2C4;
}
.ph1, 
.pricingCard1 > li > .checkIcon {
    color: #FF4F66;
}

.pricingCard2 {
    background: #D0FFE7;
}
.ph2, 
.pricingCard2 > li > .checkIcon {
    color: #11d87b;
}

.pricingCard3 {
    background: #fce1ff;
}
.ph3, 
.pricingCard3 > li > .checkIcon {
    color: #f565ff;
}

.pricingCard4 {
    background: #d2efff;
}
.ph4, 
.pricingCard4 > li > .checkIcon {
    color: #5bc5ff;
}

.pricingCard5 {
    background: #ded9ff;
}
.ph5, 
.pricingCard5 > li > .checkIcon {
    color: #755BFF;
}

.pricingCard6 {
    background: #fff1d5;
}
.ph6, 
.pricingCard6 > li > .checkIcon {
    color: #f5c800;
}


@media (max-width: 768px) {
    .pricingNav {
        display: flex;
    }
}
#testimonial {
    padding: 7rem 0 6rem;
    background: #F8F5FF;
}
.review{
    box-shadow: 1px 0 20px rgb(0 0 0 / 10%);
    padding: 1rem;
    border-radius: 0.3rem;
    margin: 5rem 0 1rem 0;
    text-align: center;
    background: #fff;
    max-width: 550px;
}
.review > img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin-top: -4rem;
    border: 5px solid #fff;
}
.review > .testimonialName {
    font-weight: 600;
    font-size: 22px;
    font-family: sans-serif;
    color: #000;
    line-height: 26px;
    margin: 0.8rem 0 0.4rem 0;
}
.review > .testimonialAddress {
    font-size: 15px;
    color: #7053F1;
}
.review > p {
    color: #777;
    margin-top: 0.8rem;
    font-size: 1rem;
    font-weight: 400;
    font-family: sans-serif;
}
.rate {
    color:#FFBE5B;
}
:root {
    --swiper-theme-color: #7053F1!important;
}
.services {
    padding: 7rem 0 6rem;
    width: 100%;
    background-color: #FAF8FF;
    font-family: 'Poppins', sans-serif;
}

.service {
    margin: 1.5rem 0;
}

.service-card {
    box-shadow: 0 0px 60px 0 rgb(0 0 0 / 2%);
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
    background: white;
    text-align: center;
    z-index: 1;
    transition: all 0.3s ease; /* Added transition for smoother effects */
}

.service-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Adjusted hover shadow */
    transform: translateY(-10px); /* Move the card up slightly */
}

.service-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 300ms ease-out;
    transform: scaleY(0);
    border-radius: 0.5rem;
    background-color: #E2DEF9;
}

.service-card:hover::before {
    transform: scaleY(1); /* Adjusted scaleY for smoother appearance */
}

.serviceImg {
    height: 90px;
    background-color: rgb(213 196 255);
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease; /* Added transition for smoother effects */
}

.service-card:hover .serviceImg {
    height: 70px;
    padding: 0.5rem 1rem;
}

.serviceDes {
    color: #666;
    font-weight: 500;
    line-height: 1.5;
    font-family: sans-serif;
    font-size: 1rem;
    margin-bottom: 0;
}

.serviceName {
    font-weight: 700;
    font-size: 1.4rem;
    padding: 1rem 0;
    margin-bottom: 0;
}

.bookingBox {
    opacity: 0;
    transition: all 0.3s ease; /* Added transition for smoother effects */
}

.service-card:hover .bookingBox {
    opacity: 1; /* Make the booking box visible on hover */
    transform: translateY(-10px); /* Move the booking box up slightly */
    margin-top: -10px; /* Adjust margin-top to align with card */
}

.bookingBtn {
    border-radius: 1.8rem;
    font-weight: 500;
    font-size: 1rem;
    outline: none;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    background: #7355F7;
    margin-top: 0.5rem;
    transition: background-color 0.3s ease; /* Added transition for button */
}

.bookingBtn:hover {
    background: #4B24F5;
}

.popImg {
    height: 45px;
    border-radius: 50%;
    cursor: pointer!important;
    margin: 0.2rem 0 0 0.7rem;
}
.popUserImg {
    height: 60px;
    border-radius: 50%;
    margin-bottom: 8px;
}
.userName, 
.userEmail{
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 700;
}
.userEmail {
    margin: 0.1rem 0 0.5rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: rgba(0,0,0,.7);
}
.profile {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    background: #fff;
    text-align: center;
    margin: 2.5rem auto;
    border-radius: 0.3rem;
    max-width: 500px;
}

.profile h2 {
    font-size: 35px;
    font-weight: 500;
    padding: 0.5rem 0;
    margin-bottom: 0;
    background-color: #EFF2F7;
}

.profile img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 1rem 0;
}

.profile h3 {
    font-size: 1.5rem;
    margin: 0.6rem 0;
}

.profile h5 {
    font-size: 1.1rem;
    color: rgba(0,0,0,.7);
}

.profileInfo {
    padding: 1.3rem;
}

.mainBtn {
    background: #7355F7;
    padding: 13px 36px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
}
.mainBtn:hover {
    background-color: #4C25F5;
}
#statusBtn {
    border: none;
    outline: none;
}
#pending {
    color: white;
    background: rgb(255 78 96);
}
#pending:hover{
    background: rgb(228 72 88);
}
#ongoing {
    color: white;
    background: rgb(73 146 255);
}
#ongoing:hover{
    background: #4185e2;
}
#done {
    color: white;
    background: rgb(31 204 123);
}
#done:hover{
    background: rgb(31 177 109);
}
.dropdown-menu{
    padding: 1px!important;
    border: 1px solid black!important;
}

#dropdown-basic-button .btn {
    border: none;
    box-shadow: none;
}
.orderList{
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
    margin-top: 2.5rem;
}
.tableTitle {
    background: #efefef;
    border-radius: 0.4rem;
    padding: 0.2rem 1rem;
}
.tableTitle p {
    margin-bottom: 0;
    font-weight: 500;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: #fcfcfc!important;
}

.table td, .table th {
    border-top: 0!important;
}



.addServiceForm{
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 2rem;
    border-radius: 1rem;
    margin: 2.5rem 0;
}
  
.uploadBtn {
    color: #6544f8;
    border: 1px solid #5c38ff;
    background-color: rgba(147, 123, 255, 0.227);
    padding: 8px 20px;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    outline: 0;
    width: 250px;
    transition: 0.4s;
}

.uploadBtn:hover {
    color: #6544f8;
    border: 1px solid #5c38ff;
    background-color: rgba(147, 123, 255, 0.384);
}

.form-label {
    margin-bottom: .6rem!important;
}

.sideBox{
    height: 100%;
}


#sideNavbar {
    font-family: 'Poppins', sans-serif;
    transition: 0.4s;
}
#sideNavbar ul {
   margin: 0;
   padding: 0.5rem 0 0.5rem 2rem;
   list-style: none;
}
#sideNavbar ul li a {
    padding: 0.6rem 0.8rem;
    display: block;
    font-size: 1.1rem;
    color: #878787;
    border-left: 3px solid transparent;
}
#sideNavbar ul li a:hover {
    text-decoration: none;
    color: #000;
    background-color: #EFF2F7;
}

.activePage{
    color: #000!important;
    background-color: #f1eeff!important;
    border-left: 3px solid #7255F6!important;
}
.activePage:hover {
    color: #000!important;
    background-color: #f1eeff!important;
    border-left: 3px solid #7255F6!important;
}
.iconC {
    margin-right: 5px;
}

.sideBrand {
    color: #000;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sideBrand h2 {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0;
}
.sideBrnIcon {
    font-size: 2.5rem;
    font-weight: 700;
    color: #7255F6;
    margin-right: 5px;
}
.bookingList {
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 1.5rem;
    margin: 0.9rem 0;
    border-radius: 0.5rem;
}
.bookingList img{
    height: 70px;
    border-radius: 0.3rem;
    padding: 0.2rem 0.5rem;
    background: #D5C4FF;
    margin-bottom: 0.5rem;
}
.bookingList h6{
    margin: 0.5rem 0;
    color: #263179;
    font-size: 1.2rem;
    font-weight: 600;
}
.serviceState {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
}

.bookForm {
    background: #fff;
    padding: 2rem 3rem;
    margin: 2.5rem 1rem;
    border-radius: 1rem;
    position: relative;
}

.form-select:focus,
.form-control:focus  {
    border-color: #8468ff!important;
    box-shadow: 0 0 0 0.2rem rgb(4 0 255 / 26%)!important;
}

.priceInput {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.activeService {
    background: #66b0ffbe;
    color: #fff;
}

.toastIcon {
    height: 1.2rem;
}

.bookToast {
    position: absolute;
    right: 0;
    top: 1.5rem;
    z-index: 111;
    background: #fff;
}
.userReviewBox {
    background: #f1ecff;
    padding: 2.5rem 5rem;
    border-radius: 0.6rem;
    text-align: center;
    min-height: 100vh;
}
.userReviewBox button {
    margin: 0 0.5rem;
}
.reviewForm {
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
    margin: 2.5rem 0;
}
#dashboard {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: stretch;
    perspective: 1500px;
    background: #f4f7fc;
    font-family: 'Poppins', sans-serif;
  }
  
  #sidebar {
    min-width: 300px;
    max-width: 300px;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;
    color: #878787;
    transition: all 0.6s cubic-bezier( 0.55, 0.055, 0.675, 0.19 ) ;
    padding-bottom: 0.5rem;
  }
  #sidebar.active {
    margin-left: -300px;
    transform: rotateY(100deg);
  }
  
  .sidebarContent {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .backBtnBox {
    position: absolute;
    bottom: 0;
    padding: 0.2rem;
    width: 100%;
  }
  
  .backBtnBox .backBtn {
    display: block;
    width: 100%;
    text-transform: uppercase;
    background: #7254F6;
    border: none;
    font-size: 1rem;
    transition: 0.3s;
    padding: 0.4rem;
    font-weight: 500;
    color: #fff;
    border-radius: 0.3rem;
  }
  
  .backBtnBox .backBtn:hover{
    background: #4C25F5;
  }
  
  .backBtnBox a:hover {
    text-decoration: none;
  }
  
  #pageContent {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    padding: 1rem;
  }
  
  .dashBoardHeader {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 2rem 0.6rem 1.3rem;
    margin-bottom: 1rem;
    background: #FFFFFF;
    align-items: center;
    border-radius: 0.2rem;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    transition: 0.4s;
    transform: button;
  }
  
  .dashBoardHeader h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }
  
  .sideToggleBtn {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
    outline: none;
    border: none;
  }
  
  /* Menu Hamburger Icon Animation */
  
  #nav-icon {
    width: 40px;
    height: 34px;
    position: relative;
    transform: rotate(0deg);
    transition: .7s ease-in-out;
    cursor: pointer;
    border-radius: 0.133rem;
    margin-right: 0.5rem;
    background: #eff2f7;
  }
  
  #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: #696969;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  #nav-icon span:nth-child(even) {
    left: 35%;
    border-radius: 0 5px 5px 0;
  }
  
  #nav-icon span:nth-child(odd) {
    left: 0.4rem;
    border-radius: 5px 0 0 5px;
  }
  
  #nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
    top: 7px;
  }
  
  #nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
    top: 15px;
  }
  
  #nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
    top: 23px;
  }
  
  #nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
    transform: rotate(45deg);
  }
  
  #nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
    transform: rotate(-45deg);
  }
  
  #nav-icon.open span:nth-child(1) {
    left: 10px;
    top: 14px;
  }
  
  #nav-icon.open span:nth-child(2) {
    left: calc(50% - 10px);
    top: 14px;
  }
  
  #nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  
  #nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  
  #nav-icon.open span:nth-child(5) {
    left: 10px;
    top: 15px;
  }
  
  #nav-icon.open span:nth-child(6) {
    left: calc(50% - 10px);
    top: 15px;
  }
  
  .adminBtn {
    margin-top: 1.8rem;
    margin-left: -1rem;
  }
  
  .makeAdmin {
      background: #FFFFFF;
      border-radius: 1rem;
      padding: 3rem 2rem 8rem;
      margin-top: 2rem;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
  }
  
.fContainer{
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

.pageCloseBtn {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  z-index: 33;
  color: #000;
  background: #F0F0F0;
  border: 1px solid #ACACAC;
  text-align: center;
  transition: 0.3s;
}
.pageCloseBtn:hover {
  background: #d6d6d6;;
}

.signInToast {
  position: absolute;
  top: 20%;
  right: 0;
  z-index: 123;
  background: #fff;
}

.signInToast .toastIcon {
  height: 1.2rem;
}

.fContainer::before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;border-radius: 50%;
  background: linear-gradient(-45deg, #795cf9, #7355F7);
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  z-index: 6;
  transition: 1.8s ease-in-out;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signIn-singUp {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  transition: 1s 0.7s ease-in-out;
}

.fContainer form {
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  overflow: hidden;
  grid-column: 1/2;
  grid-row: 1/2;
  transition: 0.2s 0.7s ease-in-out;
}

.fContainer form.sign-in-form {
  z-index: 2;
}

.fContainer form.sign-up-form {
  z-index: 1;
  opacity: 0;
}

.fContainer .title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 1rem;
  font-weight: 600;
}

.input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
}

.input-field .fIcon {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.1rem;
}

.input-field input {
  padding-right: 0.5rem;
  background-color: #F0F0F0!important;
  border-radius: 55px;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 500;
  font-size: 1.1rem;
  color: #333;
  width: 100%;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  background: #fff;
  outline: none;
  font-size: 1.1rem;
  transition: 0.3s;
  cursor: pointer;
}

.social-icon:hover {
  border: 1px solid #6e4dff;
  color: #6e4dff;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.iBtn {
  width: 160px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: #7355F7;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
  margin: 10px 0;
  transition: .5s;
}
.iBtn:hover{
  background: #4C25F5;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
  margin-bottom: 0
}

.social-media {
  display: flex;
  justify-content: center;
}

.panels-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align:center;
  z-index: 7;
}

.panel .content {
  color: #fff;
  transition: .9s .6s ease-in-out;
}

.panel h3 {
  font-weight: 600;
  line-height:1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.iBtn.transparent {
  margin: 0;
  background:none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.pImg {
  width: 100%;
  transition: 1.1s .4s ease-in-out;
}

.right-panel .content,
.right-panel .pImg {
  transform: translateX(800px);
}


/* Animation */

.fContainer.sign-up-mode::before{
  transform: translate(100%, -50%);
  right: 52%;
}

.fContainer.sign-up-mode .left-panel .pImg,
.fContainer.sign-up-mode .left-panel .content {
  transform: translateX(-800px)
}

.fContainer.sign-up-mode .right-panel .content,
.fContainer.sign-up-mode .right-panel .pImg {
  transform: translateX(0px)
}

.fContainer.sign-up-mode .left-panel {
  pointer-events: none;
}

.fContainer.sign-up-mode .right-panel {
  pointer-events:all;
}

.fContainer.sign-up-mode .signIn-singUp {
  left: 25%;
}

.fContainer.sign-up-mode form.sign-in-form {
  z-index: 1;
  opacity: 0;
}

.fContainer.sign-up-mode form.sign-up-form {
  z-index: 2;
  opacity: 1;
}

@media (max-width: 870px) {
  .fContainer {
    min-height: 800px;
    height: 100vh;
  }
  .signIn-singUp {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signIn-singUp,
  .fContainer.sign-up-mode .signIn-singUp {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .pImg {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .fContainer::before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .fContainer.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .fContainer.sign-up-mode .left-panel .pImg,
  .fContainer.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .fContainer.sign-up-mode .right-panel .pImg,
  .fContainer.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .pImg,
  .right-panel .content {
    transform: translateY(300px);
  }

  .fContainer.sign-up-mode .signIn-singUp {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .pImg {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .fContainer {
    padding: 1.5rem;
  }

  .fContainer:before {
    bottom: 72%;
    left: 50%;
  }

  .fContainer.sign-up-mode::before {
    bottom: 28%;
    left: 50%;
  }
}


